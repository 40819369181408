.navbar {
  padding-left: 20%;
  padding-right: 20%;
  box-sizing: border-box;
}

.navbar-mobile {
  padding-left: 5%;
  box-sizing: border-box;
  position: fixed;
}

.navbar-mobile .MuiButtonBase-root {
  left: -12px;
}

.navbar-mobile .MuiSvgIcon-root {
  font-size: 48px;
}

.navContainer {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.navItem {
  color: black;
  font-size: 18px;
  font-family: Gilroy-Bold;
  color: #828282;
  cursor: pointer;
}

.navItem.selected {
  color: black;
}

.navSeperator {
  padding-top: 32px;
  border-bottom: 2px solid black;
  width: 100%;
}

@media screen and (min-width: 900px) {
  .navbar {
    display: block;
  }

  .navbar-mobile {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .navbar {
    display: none;
  }

  .navbar-mobile {
    display: block;
    top: 0px;
    left: 0px;
  }
}
