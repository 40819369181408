.aboutContainer {
  background-color: #f6f6f6;
  width: 100%;
}

.aboutInner {
  padding-left: 20%;
  padding-right: 20%;
  box-sizing: border-box;
  padding-top: 48px;
  padding-bottom: 48px;
}

.title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 48px;
}

.content {
  text-align: center;
  max-width: 55%;
  margin: auto auto;
  line-height: 1.35em;
}

@media screen and (max-width: 1283px) {
  .content {
    max-width: 80%;
  }
}

@media screen and (max-width: 900px) {
  .content {
    max-width: 100%;
  }
}
