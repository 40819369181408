.skillsContainer {
  background-color: #ffffff;
  width: 100%;
}

.skillsInner {
  padding-left: 20%;
  padding-right: 20%;
  box-sizing: border-box;
  padding-top: 48px;
  padding-bottom: 48px;
}

.titleSkills {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 32px;
}

.contentSkills {
  text-align: center;
  font-size: 20px;
  padding-bottom: 48px;
}

.skillItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skillContainer {
  text-align: center;
  flex: 0 0 25%;
  padding-bottom: 24px;
}

.skillName {
  padding-top: 16px;
  white-space: pre-line;
}

@media screen and (max-width: 900px) {
  .skillContainer {
    flex: 0 0 50%;
  }
  .skillContainer:nth-child(n + 3) {
    padding-bottom: 0px;
  }
}
