.projectsContainer {
  background-color: #f6f6f6;
  width: 100%;
}

.projectsInner {
  padding-left: 20%;
  padding-right: 20%;
  box-sizing: border-box;
  padding-top: 48px;
  padding-bottom: 48px;
}

.projectsTitle {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  padding-bottom: 32px;
}

.projectContent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto auto;
  max-width: 840px;
}

.projectContainer {
  padding-bottom: 48px;
  text-align: center;
}

.projectContainer:last-of-type {
  padding-bottom: 0px;
}

.projectContainer.clickable {
  cursor: pointer;
}

.projectContainer.full {
  flex: 0 0 100%;
}

.projectContainer.full img {
  width: 100%;
}

.projectContainer.half {
  flex: 0 0 50%;
}

.projectTitle {
  padding-top: 8px;
  font-size: 24px;
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.modalContainer video {
  max-width: 70%;
}

.modalGif {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 1283px) {
  .projectContainer.full img {
    width: 100%;
  }

  .projectContainer.half img {
    width: 100%;
  }

  .modalContainer video {
    max-width: 90%;
    max-height: 90%;
  }
}

@media screen and (max-width: 900px) {
  .modalGif {
    max-width: 90%;
    max-height: 90%;
  }

  .projectContainer.half {
    flex: 0 0 100%;
  }
}
