.heroContainer {
  padding-top: 20px;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  box-sizing: border-box;
}

.heroTop {
  position: relative;
}

.heroTopItem {
  width: 33%;
  display: inline-block;
  position: relative;
}

.heroTopItem.name {
  font-size: 48px;
  font-family: Gilroy-Bold;
}

.heroTopItem.role {
  text-align: center;
  font-size: 24px;
}

.heroTopItem.language {
  position: relative;
  height: 1px;
}

.languageChoice {
  transform: rotate(-90deg);
  position: absolute;
  right: -31px;
  bottom: 21px;
  font-family: Gilroy-Bold;
}

.languageFr {
  display: inline;
  color: #828282;
  cursor: pointer;
}

.languageEn {
  color: #828282;
  cursor: pointer;
}

.languageDivider {
  color: #828282;
}

.selected {
  color: black;
}

.CircleSpin{
  max-width: 100%;
}

.heroCicleImage {
  padding-top: 48px;
  padding-bottom: 48px;
  text-align: center;
  /*position: relative;*/
  right: 70px;
  size: 50%;
}

@media screen and (max-width: 1283px) {
  .heroTopItem.name {
    width: 100%;
  }

  .heroTopItem.role {
    padding-top: 24px;
    width: 100%;
    text-align: left;
  }

  .heroTopItem.language {
    width: 100%;
    text-align: right;
    display: inherit;
  }
}

@media screen and (max-width: 900px) {
  .heroContainer {
    padding-top: 48px;
  }

  .heroTopItem.role {
    width: 90%;
  }

  .heroCicleImage img {
    max-width: 100%;
  }
}

@media screen and (max-width: 380px) {
  .heroTopItem.language {
    position: absolute;
    top: 10px;
    right: -20px;
  }
}
