body {
  margin: 0;
  font-family: Gilroy-Medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  color: #070707;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Fonts */
@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Bold"), url("./fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Medium"), url("./fonts/Gilroy-Medium.ttf") format("truetype");
}
